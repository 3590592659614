<template>
  <div class="content">
    <div class="contentBox">
      <!-- <div class="box_log loginbox" v-if="1==0"> -->
      <img class="leftBgImg" src="../assets/img/bgImg.png" alt="" />
      <div class="box_log loginbox">
        <div class="main">
          <div class="top">
            <!-- <img class="logoIcon" src="../assets/img/index01.svg" alt="" />
            <h2 class="title">八米集运系统</h2> -->
            <img class="logostyle" src="../assets/img/logoimg.png" alt="" />
          </div>
          <h3 class="tips_tit">运维系统登录</h3>
          <!-- 账号登录 -->
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0"
            class="demo-ruleForm"
          >
            <el-form-item prop="userName" class="rowItem">
              <el-input
                class="loginInput"
                placeholder="登录账号"
                v-model="ruleForm.userName"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="password" class="rowItem">
              <el-input
                class="loginInput"
                placeholder="登录密码"
                type="password"
                v-model="ruleForm.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item style="text-align: center">
              <el-button class="subBtn" type="primary" @click="submitForm()"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import common from "@/assets/js/comm";
import { parse } from "qs";
export default {
  data() {
    var checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      }
      callback();
    };
    var checkUserName2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      }
      callback();
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      }
      callback();
    };
    return {
      ruleForm: {
        password: "",
        userName: "",
        sign: "",
      },
      ruleForm2: {
        code: "",
        userName: "",
        prefix: "",
        sign: "",
      },

      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        userName: [{ validator: checkUserName, trigger: "blur" }],
      },

      systemType: 0,
      systemTypeList: [
        {
          value: 0,
          label: "运维系统",
        },
        {
          value: 1,
          label: "管理系统",
        },
      ],
      loginType: "a", //登录方式, a==账号密码登录  b==验证码登录
      codeText: "获取验证码",
      codeStatus: false, //true == 正在获取验证码 false == 没有在倒计时
      timer: "", //计时器

      UserData: {}, //租户信息
    };
  },
  //页面卸载
  Destroyed() {
    clearInterval(this.timer);
    this.timer = "";
  },
  watch: {},
  created() {
    let uk = window.localStorage.getItem("UserKey") || "";
    let ifReLogin = window.localStorage.getItem("ifReLogin") || false; //是否是重新登陆
    if (uk && !ifReLogin) {
      this.$router.push("/baseIndex");
    }

  },
  mounted() {
    this.$keyBoard(this, "onClickEnter", 13);
    // if (window.localStorage.getItem("userList")) {
    //   this.ruleForm.userName = JSON.parse(
    //     window.localStorage.getItem("userList")
    //   )[0].userName;
    // }
  },
  methods: {
    onClickEnter() {
      this.submitForm();
    },
    // 获取租户应用信息
    getTenantApp() {
      var param = {
        appStatus: "admin:app_status",
        domainName: "b9.admin.xhyitsoft.com",
      };
      Api.getTenantApp(param).then((res) => {
        if (res.data.status == "success") {
          this.UserData = res.data.result || {};
        }
      });
    },
    // 校验手机号是否已申请
    isApplyForTrial() {
      var param_a = {
        phone: this.ruleForm2.userName,
        tenantId: this.UserData.tenantId || "1",
      };

      let sign = tools.getSign(param_a) || "1";

      param_a.sign = sign;

      Api.isApplyForTrial(param_a).then((res) => {
        let applyForTrial = res.data.result.applyForTrial || false;
        let applyForTrialUrl = res.data.result.applyForTrialUrl;
        if (!applyForTrial) {
          window.location.href = applyForTrialUrl;
          this.$message.warning(res.data.message || "该手机号未申请试用");
        } else {
          this.numberSplit();
        }
      });
    },
    //点击获取验证码按钮
    // getCode() {
    //   if (this.ruleForm2.userName.length < 10) {
    //     this.$message.warning("手机号不正确");
    //     return;
    //   }
    //   this.isApplyForTrial();
    // },

    sendCode() {
      // this.numberSplit();
      // let that = this;
      // that.codeStatus = true;
      // that.codeText = 60;
      // clearInterval(that.timer);
      // that.timer = setInterval(() => {
      //   that.codeText--;
      //   if (that.codeText <= 0) {
      //     clearInterval(that.timer);
      //     that.timer = "";
      //     that.codeStatus = false;
      //     that.codeText = "获取验证码";
      //   }
      // }, 1000);
    },
    // 发送短信
    numberSplit() {
      let that = this;
      var para = {
        phone: this.ruleForm2.userName,
        prefix: common.prefix || "pc_prefix",
        smsStatus: "phoneLoginCode", //短信类型： 发送手机登陆验证码
      };
      let sign = tools.getSign(para);
      para.sign = sign;
      Api.numberSplit(para).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "发送成功");
          that.codeStatus = true;
          that.codeText = 60;
          clearInterval(that.timer);
          that.timer = setInterval(() => {
            that.codeText--;
            if (that.codeText <= 0) {
              clearInterval(that.timer);
              that.timer = "";
              that.codeStatus = false;
              that.codeText = "获取验证码";
            }
          }, 1000);
        }
      });
    },

    //验证码登录
    oauthAdminByCode() {
      let that = this;
      let param = {
        code: this.ruleForm2.code, //验证码
        userName: this.ruleForm2.userName,
        prefix: common.prefix || "pc_prefix", //短信前缀
      };
      let sg = tools.getSign(param);
      param.sign = sg;
      Api.oauthAdminByCode(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "登录成功");
          window.localStorage.setItem("UserKey", res.data.result.userKey);
          window.localStorage.setItem("ifReLogin", false); //用来判断是否是登录失效后跳转到登录页
          setTimeout(() => {
            that.$router.push({
              path: "/baseIndex",
            });
          }, 1000);
        }
      });
    },

    // submitForm() {
    //   let that = this;
    //   let signParam = {
    //     password: that.ruleForm.password,
    //     userName: that.ruleForm.userName,
    //   };
    //   let sign = tools.getSign(signParam); //调用getSign方法获取签名,该方法在tool.js里面
    //   let param = {
    //     password: that.ruleForm.password,
    //     userName: that.ruleForm.userName,
    //     sign: sign,
    //   };
    //   Api.oauthAdmin(param).then((res) => {
    //     this.$message.success(res.data.message || "登录成功");
    //     window.sessionStorage.setItem("UserKey", res.data.result.userKey);
    //     setTimeout(() => {
    //       that.$router.push({
    //         path: "/baseIndex",
    //       });
    //     }, 1000);
    //   });
    // },

    submitForm() {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //运维登录
          let siginobj = [
            {
              key: "password",
              val: that.ruleForm.password,
            },
            {
              key: "username",
              val: that.ruleForm.userName,
            },
          ];
          let sign = tools.getMD5Sign(siginobj);
          let param = {
            password: that.ruleForm.password,
            sign: sign,
            username: that.ruleForm.userName,
          };
          Api.yw_login(param).then((res) => {
            this.$message.success(res.data.message || "登录成功");
            window.localStorage.setItem("UserKey", res.data.result.token);
            setTimeout(() => {
              that.$router.push({
                path: "/MessagePanel",
              });
            }, 1000);
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang='scss'>
$myColor: #ef7c1b;
.content {
  // background: url("../assets/img/bgc3.jpg") no-repeat;
  //background: url("../assets/img/bgc1.jpg") no-repeat;
  background: url("../assets/img/bgc2.png") no-repeat;
  background-size: 100% 100%;
  background-color: #f2f2f2;
  height: 100vh;
}
.contentBox {
  width: 90%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // justify-content: flex-end;
  margin: 0 auto;
  box-sizing: border-box;
  .loginbox {
    .el-input__inner {
      // border-bottom: 1px solid #d7d7d7;

      height: 40px;
      line-height: 40px;
      min-height: 40px;
    }
  }
  .leftBgImg {
    flex: 1;
    min-width: 400px;
    max-width: 600px;
    margin-right: 100px;
    // height: 540px;
  }
  .box_log {
    // width: 25%;
    min-width: 400px;
    max-width: 600px;
    // margin: 0 auto;

    margin-right: 80px;

    .main {
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 0 10px #d7d7d7;
      // height: 360px;
      background-color: #fff;
      // box-shadow: 3px 3px 6px #f2f2f2;
      box-sizing: border-box;
      padding: 30px 50px 10px 50px;

      .top {
        width: 100%;

        // background-color: #f00;
        // position: relative;
        // display: flex;
        // align-items: center;
        position: relative;
        text-align: center;
        .logoIcon {
          width: 60px;
          height: 60px;
          margin-right: 56px;
          position: absolute;
          left: 0;
          top: 20px;
        }
        .logostyle {
          width: 70%;
        }

        .title {
          // width: 100%;
          // flex: 1;
          text-align: center;
        }
      }

      .subBtn {
        margin: 20px auto;
        background-color: #ef7c1b;
        width: 100%;
      }
      .el-button {
        border: 0;
        padding: 15px 0;
      }
    }
  }
  ::v-deep {
    .el-input__inner {
      height: 40px;
      line-height: 40px;
      min-height: 40px;
    }
  }
  .rowItem {
    // border: 1px solid #d7d7d7;
    margin-bottom: 20px;
  }
  .rowCode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .loginInput {
      flex: 1;
    }

    .activeClass {
      margin: 0;
      padding: 0 8px;
      margin-left: 10px;
      height: 38px;
      line-height: 38px;
      border: none;
      color: #fff;
      background-color: $myColor;
      border-radius: 4px;
      width: 86px;
    }
    .getCodeBtn {
      margin: 0;
      padding: 0 8px;
      margin-left: 10px;
      height: 38px;
      line-height: 38px;
      border-radius: 4px;
      width: 86px;
      border: 1px solid #d7d7d7;
      color: #333;
    }
  }
}
</style>